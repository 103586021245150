<template>
  <detail :items="items"></detail>
</template>

<script>
import Detail from "../components/Detail.vue";
export default {
  components: { Detail },
  data() {
    return {
      items: [
        {
          label: "产品中心",
          path: "/production",
        },
        {
          label: "产品介绍",
        },
      ],
    };
  },
};
</script>

<style>
</style>