<template>
  <div>
    <bread-crumb v-bind="$attrs"></bread-crumb>
    <h3 v-text="title"></h3>
    <h4 v-text="time"></h4>
    <article v-html="content" v-if="id === activeId"></article>
    <waiting v-else></waiting>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import { get } from "@/plugins/axios";
import Waiting from "@/components/Waiting.vue";
export default {
  components: { BreadCrumb, Waiting },
  data() {
    return {
      title: undefined,
      time: undefined,
      content: undefined,
      activeId: undefined,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    getContent() {
      if (!this.id) return this.ctxNotFound();
      if (this.id === this.activeId) return;
      let url;
      if (this.$attrs.items[0].label === "解决方案") {
        url = `/HomeApi/QuerySolutionById/${this.id}`;
      } else if (this.$attrs.items[0].label === "产品中心") {
        url = `/HomeApi/QueryProductById/${this.id}`;
      } else if (this.$attrs.items[0].label === "资讯中心") {
        url = `/HomeApi/QueryConsultingById/${this.id}`;
      }

      get(url)
        .then((data) => {
          console.log(data);
          this.content = data.Content;
          this.activeId = this.id;
          this.title = data.Name;
          this.time = data.Time;
        })
        .catch(() => {
          this.ctxNotFound();
        });
    },
    ctxNotFound() {
      setTimeout(() => {
        window.location.replace("/404.html");
      }, 2000);
    },
  },
  activated() {
    this.getContent();
  },
};
</script>

<style lang="less" scoped>
h3 {
  text-align: center;
  font-size: 14px;
  font-size: 36px; //3em;;
}
h4 {
  text-align: center;
  font-size: 14px;
  font-weight: normal;
}

article {
  margin: 20px auto;
  width: 60%;
  max-width: 1440px;
  min-width: 1000px;
  // font-size: 1rem;
  font-size: 14px;
  color: #333;
  text-align: left;
  line-height: 2;
  text-indent: 2em;
}

article /deep/ .title {
  font-size: larger;
  text-align: center;
  font-weight: 550;
}

// article /deep/ p::before {
//   content: "\00A0\00A0\00A0\00A0";
// }
</style>
<style lang="less">
p {
  img {
    width: 100%;
  }
}
</style>