<template>
  <div class="breadcrumb">
    <span
      v-for="(item, idx) in items"
      :key="idx"
      v-text="item.label"
      class="item"
      :class="item.path ? 'item-nav' : ''"
      @click="handleClick(item)"
    >
    </span>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleClick(item) {
      if (item.path) this.$router.push(item.path);
    },
  },
};
</script>

<style lang="less" scoped>
.breadcrumb {
  padding-left: 40px;
  height: 50px;
  display: flex;
  align-items: center;
  color: #00000072;
  border: solid #ccc;
  border-width: 0 0 1px 0;
  font-size: 14px;

  .item + .item::before {
    content: "/";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 10px;
  }

  .item-nav {
    font-weight: bolder;
    cursor: pointer;
  }

  .item-nav:hover {
    color: orange;
  }
}
</style>